import { css } from '@emotion/react';
import type { ListItemButtonProps } from '@mui/material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronRightIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/ChevronRightIcon';
import type { TpMuiSvgIcon } from '@noah-labs/fe-shared-ui-assets/types/muiSvgIcon';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

export type PpSidebarNavItem = ListItemButtonProps &
  NavLinkProps & {
    Icon: TpMuiSvgIcon;
    label: React.ReactNode;
    selected?: boolean;
  };

export function SidebarNavItem({
  Icon,
  label,
  selected,
  ...rest
}: PpSidebarNavItem): React.ReactElement {
  const theme = useTheme();

  const styles = {
    chevron: css`
      font-size: ${theme.typography.pxToRem(16)};
    `,
    icon: css`
      color: ${theme.palette.text.light};
      margin-right: ${theme.spacing(1.75)};
    `,
    selectedIcon: css`
      color: ${theme.palette.text.primary};
    `,
    selectedWrapper: css`
      background-color: ${theme.palette.grayscale[200]};
      color: ${theme.palette.text.primary};

      :hover {
        background-color: ${theme.palette.grayscale[100]};
      }
    `,
    wrapper: css`
      border-radius: ${theme.borderRadius.sm};
      padding: ${theme.spacing(1.375, 2)};
      color: ${theme.palette.text.light};
    `,
  };

  const wrapperStyles = [styles.wrapper];
  const iconStyles = [styles.icon];

  if (selected) {
    wrapperStyles.push(styles.selectedWrapper);
    iconStyles.push(styles.selectedIcon);
  }

  return (
    <ListItemButton component={NavLink} css={wrapperStyles} {...rest}>
      <ListItemIcon css={iconStyles}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={label} primaryTypographyProps={{ variant: 'paragraphBodySBold' }} />
      {!selected && <ChevronRightIcon css={[styles.icon, styles.chevron]} />}
    </ListItemButton>
  );
}
